import React from "react"
import * as styles from "./footer.module.scss"
import { Link } from "gatsby"
import SocialIcons from "../social-icons/SocialIcons"
const Credits = ({ footerNavItems, companyName, social }) => {
  let date = new Date().getFullYear()
  return (
    <section className={` ${styles.credits}`}>
      <div className={styles.creditsWrapper}>
        <div>
          Copyright © {date} {companyName} - All Rights Reserved.{" "}
          {footerNavItems.map(({ elements }, i) => {
            const { page, link_text, external_link } = elements
            const text = link_text.value
            const slug = page.value[0].elements.slug.value
            return (
              <span key={slug + i}>
                {external_link.value.length > 0 ? (
                  <>
                    /{" "}
                    <a
                      href={external_link.value}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.footerNav}
                    >
                      {text}
                    </a>{" "}
                  </>
                ) : (
                  <>
                    /{" "}
                    <Link to={`/${slug}`} className={styles.footerNav}>
                      {text}
                    </Link>{" "}
                  </>
                )}
              </span>
            )
          })}
        </div>
        <div className={styles.socialWrapper}>
          Follow Us
          {social.value.map(({ id, elements }) => {
            return <SocialIcons key={id} icons={elements} />
          })}
        </div>
      </div>
    </section>
  )
}

export default Credits
