import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FaBars } from "react-icons/fa"
import { AppContext } from "../context/context"
import MainNavigation from "../main-navigation/MainNavigation"
import * as styles from "./header.module.scss"
import { FaPhoneAlt } from "react-icons/fa"

const Header = ({
  logo,
  sticky,
  navItems,
  whereWeServiceActive,
  whereWeServiceSlug,
  isLandingPage = false,
  phone,
}) => {
  const { size, handleOpenNav } = React.useContext(AppContext)
  const [brand] = logo
  if (!isLandingPage && size < 768) {
    return (
      <header
        className={
          isLandingPage
            ? sticky
              ? `${styles.navBarLp} ${styles.navBarSticky}`
              : styles.navBarLp
            : sticky
            ? `${styles.navBar} ${styles.navBarSticky} `
            : styles.navBar
        }
      >
        <div className={`container ${styles.navBarInner}`}>
          <Link to="/" className={styles.logoWrapper}>
            <img
              src={brand.fluid.src}
              alt={brand.description}
              className={styles.logo}
            />
          </Link>
          <FaBars className={styles.menu} onClick={handleOpenNav} />
        </div>
      </header>
    )
  } else {
    return (
      <header
        className={
          isLandingPage
            ? sticky
              ? `${styles.navBarLp} ${styles.navBarSticky}`
              : styles.navBarLp
            : sticky
            ? `${styles.navBar} ${styles.navBarSticky} `
            : styles.navBar
        }
      >
        <div className={`container ${styles.navBarInner}`}>
          {isLandingPage ? 
            <img
            src={brand.fluid.src}
            alt={brand.description}
            className={styles.logo}
            />
            :
            <Link to="/" className={styles.logoWrapper}>
            <img
              src={brand.fluid.src}
              alt={brand.description}
              className={styles.logo}
            />
          </Link>
        }
          {!isLandingPage && (
            <nav className={styles.mainNav}>
              {navItems.map(({ id, elements }) => {
                return (
                  <MainNavigation
                    key={id}
                    navItem={elements}
                    whereWeServiceActive={whereWeServiceActive}
                    whereWeServiceSlug={whereWeServiceSlug}
                  />
                )
              })}
              <FaBars className={styles.menu} onClick={handleOpenNav} />
            </nav>
          )}
          {isLandingPage &&
            (size > 490 ? (
              <span className={styles.phoneLink}>
                Call Today! <a href={`tel: ${phone.value}`}>{phone.value}</a>
              </span>
            ) : (
              <a className={styles.phoneIcon} href={`tel: ${phone.value}`}>
                <FaPhoneAlt />
              </a>
            ))}
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  logo: PropTypes.array,
  sticky: PropTypes.bool,
}

Header.defaultProps = {
  logo: [],
  sticky: false,
  phone: "(207) 888-8888",
}

export default Header
