import React from "react"
import * as styles from "./footer.module.scss"
import { Link } from "gatsby"
import SocialIcons from "../social-icons/SocialIcons"

const LandingCredits = ({ footerNavItems, companyName, social }) => {
  let date = new Date().getFullYear()
  return (
    <section className={`${styles.credits}`}>
      <div className={styles.creditsWrapper}>
        <div>
          Copyright © {date} {companyName} - All Rights Reserved.{" "}
          {footerNavItems.map(({ elements }, i) => {
            const external_link = elements?.external_link?.value ?? ""
            const text = elements?.link_text?.value ?? ""
            const slug = elements?.page?.value[0]?.elements?.slug?.value ?? ""

            return (
              <span key={slug + i}>
                {external_link.length > 0 ? (
                  <>
                    /{" "}
                    <a
                      href={external_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.footerNav}
                    >
                      {text}
                    </a>{" "}
                  </>
                ) : (
                  <>
                    /{" "}
                    <Link
                      to={slug.startsWith("/") ? slug : `/${slug}`}
                      className={styles.footerNav}
                    >
                      {text}
                    </Link>{" "}
                  </>
                )}
              </span>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default LandingCredits
