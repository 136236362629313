import React from "react"
import { Link } from "gatsby"
import * as styles from "./main-navigation.module.scss"
const MainNavigation = ({
  navItem,
  whereWeServiceSlug,
  whereWeServiceActive,
}) => {
  const { link_text, page } = navItem
  const { slug } = page.value[0].elements
  const { __typename } = page.value[0]
  let newSlug = `/${slug.value}`

  if (
    __typename === "kontent_item_service_area" &&
    whereWeServiceActive === "true"
  ) {
    newSlug = `/${whereWeServiceSlug}/${slug.value}`
  }
  return (
    <Link
      to={newSlug}
      className={styles.link}
      activeClassName={styles.current}
    >
      {link_text.value}
    </Link>
  )
}

export default MainNavigation
