import React from "react"
import * as styles from "./footer.module.scss"
import { Link } from "gatsby"
import Credits from "./Credits"
import FooterLocation from "./FooterLocation"
import PropTypes from "prop-types"
import { MdPermPhoneMsg } from "react-icons/md"
import LandingCredits from "./LandingCredits"

const Footer = ({
  companyName,
  social,
  logo,
  location,
  trackingPhone,
  footerNavItems,
  additionalOffices,
  isLandingPage = false,
}) => {
  const [brand] = logo.value
  const [office] = location.value

  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <Link to="/" className={styles.logo}>
          <img src={brand.fluid.src} alt={brand.description} />
        </Link>
        <div className={styles.footerLeft}>
          <div className={styles.locationWrapper}>
            <FooterLocation
              location={office.elements}
              companyName={companyName}
            />
            {additionalOffices &&
              additionalOffices.value.map(({ elements, id }) => {
                return <FooterLocation location={elements} key={id} />
              })}
          </div>
        </div>
        <div className={styles.footerRight}>
          <MdPermPhoneMsg />
          <a
            href={`tel:${trackingPhone}`}
            aria-label="call button"
            className={styles.phone}
          >
            {trackingPhone}
          </a>
        </div>
      </div>
      {isLandingPage ? (
        <LandingCredits
          footerNavItems={footerNavItems}
          companyName={companyName}
        />
      ) : (
        <Credits
          footerNavItems={footerNavItems}
          companyName={companyName}
          social={social}
        />
      )}
    </footer>
  )
}

Footer.propTypes = {
  social: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  trackingPhone: PropTypes.string.isRequired,
}

Footer.defaultProps = {
  social: {},
  logo: [],
  location: [],
  trackingPhone: "(207) 888-8888",
}

export default Footer
