import React from "react"
import PropTypes from "prop-types"
import * as styles from "./footer.module.scss"
const FooterLocation = ({ location, companyName }) => {
  const { address1, address2, city, state, zip, phone, name } = location
  const { name: stateName } = state.value[0]
  return (
    <div className={styles.location}>
      <span
        itemScope=""
        itemType="http://schema.org/LocalBusiness"
        itemRef="header-logo"
      >
        <p className={styles.address}>
          <strong itemProp="name">{companyName || name.value}</strong>
          <br />
          <span
            itemProp="address"
            itemScope=""
            itemType="http://schema.org/PostalAddress"
            itemRef="_addressCountry10"
          >
            <span itemProp="streetAddress">
              {address1 && address1.value}{" "}
              {address2.value && address2.value.trim()}
            </span>
            <br />
            <span itemProp="addressLocality">{city && city.value}, </span>
            <span itemProp="addressRegion" className={styles.state}>
              {stateName && stateName}{" "}
            </span>
            <span itemProp="postalCode">{zip.value && zip.value}</span>
            <br />
            {/* <meta itemprop="url" /> TODO add URL and LOGO to the location element" */}
            <meta
              id="_addressCountry10"
              itemProp="addressCountry"
              content="United States"
            />
            <span itemProp="telephone">{phone.value && phone.value}</span>
          </span>
        </p>
      </span>
    </div>
  )
}

FooterLocation.propTypes = {
  location: PropTypes.object.isRequired,
}

FooterLocation.defaultProps = {
  location: {},
}

export default FooterLocation
